import React from 'react'
import Layout from 'layouts/en'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button, Table, Input } from 'components'
import { TryOrSchedule, Calculator } from 'containers/en'

export default class Pricing extends React.Component {
  state = {
    calculator: false,
  }

  calculator = e => {
    this.setState({ calculator: true })
  }

  render() {
    return (
      <Layout p={5}>
        <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
          <Title mb={5} fontSize={[7,9]}>Stairlin is completely free<br/> for starters</Title>
          <Text tag={'h2'} mb={7} fontSize={[6,8]}>Pay only for what you use with no lock-in</Text>

          <Div display={['block','flex']} justifyContent={'space-between'}>
            <Div>
              <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                <li><Text mb={[1,3]} fontSize={[3,7]}>• No upfront costs</Text></li>
                <li><Text mb={[1,3]} fontSize={[3,7]}>• Pay-as-you-go</Text></li>
                <li><Text mb={[1,3]} fontSize={[3,7]}>• No termination fees</Text></li>
              </ul>
              <Div display={['block','none']}>
                <TryOrSchedule onClick={this.calculator} linkText={`Estimate your bill`} justCTA={true} />
              </Div>
            </Div>
            <Div mx={[-36,0]} display={[this.state.calculator ? 'block' : 'none','block']}>
              <Box bg={'white'} p={[3,5]}>
                <Calculator />
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} mt={[7,8]}>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>What makes us different</Title>
              <Text fontSize={[2,3]}>Unlike traditional services, we don’t rely on long-term contracts to keep our customers. Customers have many reasons to continue to use Stairlin including our competitive pricing, simplicity, innovative solutions, and strong security principles and practices. We’re confident that we can earn your business without prepaid lock-in.</Text>
            </Div>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Free for starters</Title>
              <Text fontSize={[2,3]}>We know how hard it is to start a business. There can be a lot of upfront costs coupled with uncertainty. That’s why we have created a free plan, so that you can focus on growing and building your business by offering all of Stairlin’s features to your customers and yourself.</Text>
            </Div>
          </Div>
          <Div display={['block','flex']}>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Truly unlimited services</Title>
              <Text fontSize={[2,3]}>Unlike traditional service providers who have "unlimited services"***, our platform gives you access to truly unlimited amount of reservations, reminders, invoices, emails, SMS, and more. No matter how your business is doing, we’ve got you covered.

                *** insert hidden cap here</Text>
            </Div>
            <Div flex={1} mb={[6,7]} mr={[0,120]} textAlign={['center','left']}>
              <Title mb={4} fontSize={[3,6]}>Pay less by using more</Title>
              <Text fontSize={[2,3]}>With Stairlin, you can get volume-based discounts and realise important savings as your usage increases. For services such as notifications, pricing is tiered, meaning the more you send notifications, the less you pay per e-mail/SMS. Large-scale businesses get discounts up to 80% from our base price with our sustained use pricing.</Text>
            </Div>
          </Div>

          <Div mt={[5,6]}>
            <Title mb={4} fontSize={[6,8]}>Price list</Title>
            <Text fontSize={[3,5]}>Check below for pricing details on each Stairlin product. Products marked early access, alpha, or beta may not have charges associated with usage in their current launch stage, which is subject to change.</Text>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Reservations</Title>
              <Text fontSize={[2,5]}>Reservation charges are based on the number of reservations which took place.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Tier</Table.Th>
                      <Table.Th fontSize={3} p={2}>Reservations</Table.Th>
                      <Table.Th fontSize={3} p={2}>Price per reservation (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Free</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>11 - 25</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.50</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>26 - 50</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.33</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>51 - 100</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.22</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>101 - 125</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.14</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>126+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.09</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Pricing examples:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>If you made 10 reservations in a month, your cost is CHF 0. (Your first 10 reservations in a month are free.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you made 20 reservations and cancelled 10 reservations in a month, your cost is CHF 0. (Your first 10 reservations in a month are free. Cancelled reservations are deducted.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you made 25 reservations in a month, your cost is CHF 7.50. (Your first 10 reservations in a month are free. The remaining reservations’ cost is calculated as 15 * CHF 0.50 = CHF 7.50.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you made 100 reservations in a month, your cost is CHF 26.75. (Your first 10 reservations in a month are free. The remaining reservations’ cost is calculated as 15 * CHF 0.50 = CHF 7.50 + 25 * CHF 0.33 = CHF 8.25 + 50 * CHF 0.22 = CHF 11.00 = CHF 26.75.)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Notifications</Title>
              <Text fontSize={[2,5]}>Notifications charges are based on the number of notifications delivered on behalf of your business, which include notifications for your customers and yourself. We currently support email, SMS, and WhatsApp notifications.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Tier</Table.Th>
                      <Table.Th fontSize={3} p={2}>Notifications</Table.Th>
                      <Table.Th fontSize={3} p={2}>Price per notification (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Free</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>36 - 88</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.18</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>89 - 175</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.14</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>176 - 350</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.10</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>351 - 438</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.07</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>439+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.05</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Pricing examples:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>If you sent 35 notifications in a month (e.g. 10 emails and 25 SMS), your cost is CHF 0. (Your first 35 notifications in a month are free.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you sent 100 notifications in a month, your cost is CHF 14.72. (Your first 35 notifications in a month are free. The remaining invoices’ cost is calculated as 53 * CHF 0.18 = CHF 9.54 + 12 * CHF 0.14 = CHF 1.68 → CHF 11.22)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div tag={'article'}>
            <Div mt={[6,8]}>
              <Title mb={4} fontSize={[3,7]}>Invoices</Title>
              <Text fontSize={[2,5]}>Invoices charges are based on the number of invoices released. Voided and written off invoices are deducted.</Text>
            </Div>
            <Div mt={[6,7]} mb={6}>
              <Div display={['block','flex']}>
                <Box bg={'white'} height={['auto','fit-content']} m={0} p={[2,4]} display={'inline-flex'}>
                  <Table width={['auto',547]}>
                    <Table.Tr>
                      <Table.Th fontSize={3} p={2}>Tier</Table.Th>
                      <Table.Th fontSize={3} p={2}>Invoices</Table.Th>
                      <Table.Th fontSize={3} p={2}>Price per invoice (CHF)</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Free</Table.Td>
                      <Table.Td fontSize={3} p={2}>0 - 10</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.00</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Atto</Table.Td>
                      <Table.Td fontSize={3} p={2}>11 - 25</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.50</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Femto</Table.Td>
                      <Table.Td fontSize={3} p={2}>26 - 40</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.30</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Pico</Table.Td>
                      <Table.Td fontSize={3} p={2}>41 - 80</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.18</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Nano</Table.Td>
                      <Table.Td fontSize={3} p={2}>81 - 100</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.11</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td fontSize={3} p={2}>Micro</Table.Td>
                      <Table.Td fontSize={3} p={2}>101+</Table.Td>
                      <Table.Td fontSize={3} p={2}>0.06</Table.Td>
                    </Table.Tr>
                  </Table>
                </Box>
                <Div ml={6} display={['none','block']}>
                  <Text mb={4} fontSize={3}>Pricing examples:<br/>
                    <ol>
                      <li><Text mb={4} fontSize={3}>If you released 10 invoices in a month, your cost is CHF 0. (Your first 10 invoices in a month are free.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you released 20 invoices, voided 5 invoices, and wrote off 5 other invoices in a month, your cost is CHF 0. (Your first 10 invoices in a month are free. Voided and written off invoices are deducted.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you released 25 invoices in a month, your cost is CHF 7.50. (Your first 10 invoices in a month are free. The remaining invoices’ cost is calculated as 15 * CHF 0.50 = CHF 7.50.)</Text></li>
                      <li><Text mb={4} fontSize={3}>If you made 100 invoices in a month, your cost is CHF 24.00. (Your first 10 invoices in a month are free. The remaining invoices’ cost is calculated as 15 * CHF 0.50 = CHF 7.50 + 15 * CHF 0.30 = CHF 4.50 + 40 * CHF 0.18 = CHF 7.20 + 20 * 0.11 = CHF 2.2 → CHF 21.40.)</Text></li>
                    </ol>
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div mt={[8, 120]}>
            <TryOrSchedule />
          </Div>
        </Div>
      </Layout>
    )
  }
}
